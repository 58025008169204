// eslint-disable-file
@font-face {
	font-family: 'Suisse Intl Bold';
	src: url('../fonts/SuisseIntl/SuisseIntl-Bold-WebS.woff2') format('woff2'),
  url('../fonts/SuisseIntl/SuisseIntl-Bold-WebS.woff') format('woff');
  font-display: swap;
}
@font-face {
	font-family: 'Suisse Intl Medium';
	src: url('../fonts/SuisseIntl/SuisseIntl-Medium-WebS.woff2') format('woff2'),
  url('../fonts/SuisseIntl/SuisseIntl-Medium-WebS.woff') format('woff');
  font-display: swap;
}
@font-face {
	font-family: 'Suisse Intl Regular';
	src: url('../fonts/SuisseIntl/SuisseIntl-Regular-WebS.woff2') format('woff2'),
  url('../fonts/SuisseIntl/SuisseIntl-Regular-WebS.woff') format('woff');
  font-display: swap;
}
@font-face {
	font-family: 'Suisse Intl Book';
	src: url('../fonts/SuisseIntl/SuisseIntl-Book-WebS.woff2') format('woff2'),
  url('../fonts/SuisseIntl/SuisseIntl-Book-WebS.woff') format('woff');
  font-display: swap;
}
