.site-header {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  // transform: translateY(-100%);
  // height: 0;
  // transition: background-color var(--menu-transition) ease 0s;

  > .navbar {
    transition: transform var(--menu-transition) ease 0s, background-color var(--menu-transition) ease 0s;
    width: 100%;
    // transform: translateY(100%);

    .navbar-inner {
      max-width: var(--content-max-width);
    }

    .header-nav {
      display: none;
      font-size: 1.6rem;

      @include breakpoint(xs) {
        display: flex;
        gap: 2rem;
      }
    }
  }

  &.-nav-down,
  &.-nav-bg {
    > .navbar {
      background-color: var(--color-white);
    }
  }

  &.-nav-open {
    > .navbar {
      background-color: var(--color-light-grey_1);
    }
  }

  &.-nav-up {
    > .navbar {
      transform: translateY(-100%);
      // transform: translateY(0);
    }
  }
}

// .headroom {
//   @apply transition-all duration-300 ease-in;
//
//   &,
//   &--top {
//     @apply fixed; // #{!important};
//   }
//
//   &--pinned,
//   &--unpinned {
//
//     &:not(.headroom--top) {
//       @apply bg-gray-dark;
//
//       header {
//         @apply py-6;
//
//       }
//
//       .logo {
//         @apply xl:h-12;
//       }
//     }
//   }
// }

.hamburger {
  @apply h-5 w-6 flex flex-col justify-between transition-all duration-300 ease-in;

  &:hover {
    @apply cursor-pointer;
  }

  &__item {
    @apply bg-black h-0.5 w-full;
    transition: transform 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95),
    opacity 300ms linear;

    &--first {
      .hamburger--is-open & {
        @apply translate-y-2.5 rotate-45;
      }
    }

    &--middle {
      .hamburger--is-open & {
        @apply opacity-0;
      }
    }

    &--last {
      .hamburger--is-open & {
        @apply -translate-y-2 -rotate-45;
      }
    }
  }
}


.slide {
  &-enter,
  &-leave-to {
    // @apply translate-x-full transition-all duration-150 ease-in;
    transform: translateX(100%);

    .sidebar-backdrop {
      opacity: 0;
      transform: translateX(100%);
    }

    &-active {
      // @apply transform duration-200 ease-in;
    }
  }
}

.sidebar {
  @apply -z-[1] fixed;

  .sidebar-backdrop,
  .sidebar-panel {
    @apply fixed top-0 left-0;
  }

  .sidebar-backdrop {
    @apply bg-gray-dark;
  }
  .sidebar-panel {

  }

  .sidebar-backdrop {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity var(--default-transition) ease 0s, transform 0s ease var(--default-transition);
    @apply w-screen h-screen cursor-pointer;
  }

  .sidebar-panel {
    transition: transform var(--default-transition) ease 0s;

    @apply left-0 h-screen z-10 w-full;
    overflow-y: auto;
    position: fixed;
    display: flex;
    align-items: start;

    .panel-inner {
      flex: 1;
      margin: auto;
      max-width: var(--content-max-width);
      padding-left: var(--component-outer-spacing);
      padding-right: var(--component-outer-spacing);
      @include grid-layout();
      --row-gap: 2rem;

      @include breakpoint("sm") {
        align-items: center;
      }

//       .text-column,
//       .image-column {
//         @include breakpoint("sm") {
//           grid-column: span var(--col-count);
//         }
//
//         @include breakpoint("lg") {
//           grid-column: span 6;
//         }
//       }

      .text-column {
        @include breakpoint("sm") {
          order: 2;
          grid-column: 7 / span 6;
        }

        @include breakpoint("lg") {
          // order: 2;
        }
      }

      .image-column {
        @include breakpoint("sm") {
          order: 1;
          grid-column: span 5;
        }

        @include breakpoint("lg") {
          // order: 1;
        }
      }
    }
    // top: 9.6rem;
  }

  .nav-secondary {
    .nav-item {
      & + .nav-item {
        margin-top: .5em;
      }
    }
  }

  nav {
    @apply font-SuisseIntlRegular text-xl xl:text-2xl;

    // &:not(:nth-last-child(1)) {
    //   @apply mb-8;
    // }

    &.nav-primary {
      @apply font-SuisseIntlMedium text-3xl xl:text-5xl;
      margin-bottom: 3rem;
    }

    .-mobile-item {
      @include breakpoint(xs) {
        @apply hidden;
      }
    }

    &:nth-last-child(1) {
      a {
        @apply text-brand-green;

        &,
        &.router-link-active {
          @apply decoration-2;
        }
      }
    }

    .nav-item {
      &:not(:nth-last-child(1)) {
        // @apply mb-5;
      }
    }

    a {
      text-decoration-color: transparent;

      &:hover,
      &:active,
      &:focus {
        text-decoration-color: inherit;
      }

      @apply transition-colors duration-300;

      &,
      &.router-link-active {
        @apply relative underline decoration-4;
      }

      &.router-link-active {
        @apply decoration-inherit;
      }
    }
  }
}

.menu--is-open {
  .sidebar-backdrop {
    opacity: 1;
    transform: translateX(0);
    transition: opacity var(--default-transition) ease 0s, transform 0s ease 0s;
  }
  .header {
    @apply bg-gray-dark;
  }
}
