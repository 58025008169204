figure.illustration {
  // height: 90vw;
  // max-height: 80rem;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  flex-direction: column;

  @include breakpoint("sm") {
    // height: auto;
    // max-height: 70vh;
  }

  svg {
    min-width: 0;
    min-height: 0;
    // flex: 0 1 0%;
    // margin: auto;

    flex: 1 1 0%;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
  }
}

.veien {
  .cls-1 {
    fill: #e3dcd8;
  }

  .cls-2 {
    fill: #fff;
  }

  .cls-3 {
    fill: #f2c3aa;
  }

  .cls-4 {
    fill: #ffc5d0;
  }

  .cls-5 {
    fill: #fcddea;
  }

  .cls-6 {
    fill: #efe8e4;
  }

  .cls-7 {
    fill: #fdb200;
  }

  .cls-8 {
    fill: #7ce9cc;
  }

  .cls-9 {
    fill: #751f08;
  }

  .cls-10 {
    fill: #d59e39;
  }

  .cls-11 {
    fill: #e31836;
  }

  .cls-12 {
    fill: #093640;
  }

  .cls-13 {
    fill: #355edb;
  }
}

.kartGreen {
  .cls-1 {
    fill: #7ce9cc;
  }

  .cls-2 {
    fill: #defaf2;
  }

  .cls-3 {
    fill: #093640;
  }
}

.kartBlue  {
  .cls-1 {
    fill: #dae2ff;
  }

  .cls-2 {
    fill: #093640;
  }

  .cls-3 {
    fill: #355edb;
  }
}

.baby1 {
  .cls-1 {
    fill: #f4ad7d;
  }

  .cls-2 {
    fill: #020202;
  }

  .cls-3 {
    fill: #eb9047;
  }

  .cls-4 {
    fill: #ffd88a;
  }

  .cls-5 {
    fill: #fce7ea;
  }

  .cls-6 {
    fill: #dae2ff;
  }

  .cls-7 {
    fill: #e31836;
  }

  .cls-8 {
    fill: #093640;
  }
}

.baby2 {
  .cls-1 {
    fill: #f4ad7d;
  }

  .cls-2 {
    fill: #f4cdb3;
  }

  .cls-3 {
    fill: #f7f5f3;
  }

  .cls-4 {
    fill: #020202;
  }

  .cls-5 {
    fill: #eb9047;
  }

  .cls-6 {
    fill: #ffd88a;
  }

  .cls-7 {
    fill: #fce7ea;
  }

  .cls-8 {
    fill: #ff7b31;
  }

  .cls-9 {
    fill: #fdb200;
  }

  .cls-10 {
    fill: #d1bcae;
  }

  .cls-11 {
    fill: #dae2ff;
  }

  .cls-12 {
    fill: #e31836;
  }

  .cls-13 {
    fill: #093640;
  }

  .cls-14 {
    fill: #355edb;
  }
}

.kvinne1 {
  .cls-1 {
    fill: #f4cdb3;
  }

  .cls-2 {
    fill: #f7f5f3;
  }

  .cls-3 {
    fill: #020202;
  }

  .cls-4 {
    fill: #fff;
  }

  .cls-5 {
    fill: #fdb200;
  }

  .cls-6 {
    fill: #e31836;
  }

  .cls-7 {
    fill: #093640;
  }

  .cls-8 {
    fill: #355edb;
  }

  .cls-9 {
    fill: #562c05;
  }
}

.kvinne2 {
  .cls-1 {
    fill: #f4cdb3;
  }

  .cls-2 {
    fill: #f7f5f3;
  }

  .cls-3 {
    fill: #e7cfaf;
  }

  .cls-4 {
    fill: #fff;
  }

  .cls-5 {
    fill: #fff0d9;
  }

  .cls-6 {
    fill: #fdb200;
  }

  .cls-7 {
    fill: #e31836;
  }

  .cls-8 {
    fill: #355edb;
  }

  .cls-9 {
    fill: #562c05;
  }
}

.henviser {
  .cls-1 {
    fill: #f4cdb3;
  }

  .cls-2 {
    fill: #e7cfaf;
  }

  .cls-3 {
    fill: #fc816b;
  }

  .cls-4 {
    fill: #fff;
  }

  .cls-5 {
    fill: #ffc5d0;
  }

  .cls-6 {
    fill: #ecf0ff;
  }

  .cls-7 {
    fill: #7ce9cc;
  }

  .cls-8 {
    fill: #d3d3d3;
  }

  .cls-9 {
    fill: #dae2ff;
  }

  .cls-10 {
    fill: #e31836;
  }

  .cls-11 {
    fill: #093640;
  }

  .cls-12 {
    fill: #355edb;
  }
}

.gravid1 {
  .cls-1 {
    fill: #f4cdb3;
  }

  .cls-2 {
    fill: #fdb200;
  }

  .cls-3 {
    fill: #e31836;
  }

  .cls-4 {
    fill: #355edb;
  }

  .cls-5 {
    fill: #843a08;
  }
}

.gravid2 {
  .cls-1 {
    fill: #fdb200;
  }

  .cls-2 {
    fill: #e31836;
  }

  .cls-3 {
    fill: #843a08;
  }
}

.gravid3 {
  .cls-1 {
    fill: #f4cdb3;
  }

  .cls-2 {
    fill: #fdb200;
  }

  .cls-3 {
    fill: #355edb;
  }
}

.familiesykepleier {
  .cls-1 {
    fill: #f4cdb3;
  }

  .cls-2 {
    fill: #f7f5f3;
  }

  .cls-3 {
    fill: #ff7b31;
  }

  .cls-4 {
    fill: #fdb200;
  }

  .cls-5 {
    fill: #d1bcae;
  }

  .cls-6 {
    fill: #355edb;
  }
}

.deltaker1 {
  .cls-1 {
    fill: #f7f5f3;
  }

  .cls-2 {
    fill: #e3dcd8;
  }

  .cls-3 {
    fill: #f2c3aa;
  }

  .cls-4 {
    fill: #ffc5d0;
  }

  .cls-5 {
    fill: #fcddea;
  }

  .cls-6 {
    fill: #fdb200;
  }

  .cls-7 {
    fill: #7ce9cc;
  }

  .cls-8 {
    fill: #751f08;
  }

  .cls-9 {
    fill: #d59e39;
  }

  .cls-10 {
    fill: #e31836;
  }

  .cls-11 {
    fill: #093640;
  }

  .cls-12 {
    fill: #355edb;
  }
}

.deltaker2 {
  .cls-1 {
    fill: #f7f5f3;
  }

  .cls-2 {
    fill: #e3dcd8;
  }

  .cls-3 {
    fill: #f2c3aa;
  }

  .cls-4 {
    fill: #ffc5d0;
  }

  .cls-5 {
    fill: #fcddea;
  }

  .cls-6 {
    fill: #fdb200;
  }

  .cls-7 {
    fill: #7ce9cc;
  }

  .cls-8 {
    fill: #751f08;
  }

  .cls-9 {
    fill: #d59e39;
  }

  .cls-10 {
    fill: #e31836;
  }

  .cls-11 {
    fill: #093640;
  }

  .cls-12 {
    fill: #355edb;
  }
}

.deltaker3 {
  .cls-1 {
    fill: #f7f5f3;
  }

  .cls-2 {
    fill: #020202;
  }

  .cls-3 {
    fill: #fff;
  }

  .cls-4 {
    fill: #f2c3aa;
  }

  .cls-5 {
    fill: #fcddea;
  }

  .cls-6 {
    fill: #efe8e4;
  }

  .cls-7 {
    fill: #fdb200;
  }

  .cls-8 {
    fill: #7ce9cc;
  }

  .cls-9 {
    fill: #751f08;
  }

  .cls-10 {
    fill: #d59e39;
  }

  .cls-11 {
    fill: #e31836;
  }

  .cls-12 {
    fill: #093640;
  }

  .cls-13 {
    fill: #355edb;
  }

  .cls-14 {
    fill: gray;
  }

  .cls-15 {
    fill: #562c05;
  }
}

.deltaker4 {
  .cls-1 {
    fill: #020202;
  }

  .cls-2 {
    fill: #e3dcd8;
  }

  .cls-3 {
    fill: #fff;
  }

  .cls-4 {
    fill: #f2c3aa;
  }

  .cls-5 {
    fill: #ffc5d0;
  }

  .cls-6 {
    fill: #fcddea;
  }

  .cls-7 {
    fill: #efe8e4;
  }

  .cls-8 {
    fill: #fdb200;
  }

  .cls-9 {
    fill: #7ce9cc;
  }

  .cls-10 {
    fill: #751f08;
  }

  .cls-11 {
    fill: #dae2ff;
  }

  .cls-12 {
    fill: #d59e39;
  }

  .cls-13 {
    fill: #e31836;
  }

  .cls-14 {
    fill: #093640;
  }

  .cls-15 {
    fill: #355edb;
  }
}

.deltaker5 {
  .cls-1 {
    fill: #f4cdb3;
  }

  .cls-2 {
    fill: #ffe09f;
  }

  .cls-3 {
    fill: #fc816b;
  }

  .cls-4 {
    fill: #ef7938;
  }

  .cls-5 {
    fill: #f2c3aa;
  }

  .cls-6 {
    fill: #ffc5d0;
  }

  .cls-7 {
    fill: #fcddea;
  }

  .cls-8 {
    fill: #efe8e4;
  }

  .cls-9 {
    fill: #fdb200;
  }

  .cls-10 {
    fill: #7ce9cc;
  }

  .cls-11 {
    fill: #751f08;
  }

  .cls-12 {
    fill: #dae2ff;
  }

  .cls-13 {
    fill: #e31836;
  }

  .cls-14 {
    fill: #093640;
  }

  .cls-15 {
    fill: #355edb;
  }
}

.deltakerreisen-trekning-gruppe1 {
  .cls-1,.cls-2,.cls-3,.cls-4,.cls-5,.cls-6,.cls-7,.cls-8,.cls-9{
    fill:none;
  }
  .cls-10{
    fill:#923f00;
  }
  .cls-11{
    fill:#fd7932;
  }
  .cls-12{
    fill:#ffc1cf;
  }
  .cls-13{
    fill:#d8e1ff;
  }
  .cls-2{
    stroke:#003741;
  }
  .cls-2,.cls-3,.cls-4,.cls-5,.cls-6,.cls-7,.cls-8,.cls-9{
    stroke-miterlimit:10;
  }
  .cls-2,.cls-7{
    stroke-width:2.67px;
  }
  .cls-14{
    fill:#ffa974;
  }
  .cls-15{
    fill:#49ecca;
  }
  .cls-16{
    fill:#3a0406;
  }
  .cls-17{
    fill:#2e5ae2;
  }
  .cls-18{
    fill:#003741;
  }
  .cls-3{
    stroke-width:2.38px;
  }
  .cls-3,.cls-4,.cls-5,.cls-6,.cls-7,.cls-8,.cls-9{
    stroke:#000;
  }
  .cls-4{
    stroke-width:2.33px;
  }
  .cls-19{
    fill:#d7cac3;
  }
  .cls-5{
    stroke-width:3.62px;
  }
  .cls-20{
    fill:#f9d1bd;
  }
  .cls-6{
    stroke-width:2.8px;
  }
  .cls-8{
    stroke-width:2.49px;
  }
  .cls-9{
    stroke-width:2.39px;
  }
}

.deltakerreisen-trekning-gruppe2 {
  .cls-1,.cls-2,.cls-3,.cls-4,.cls-5,.cls-6{
    fill:none;
  }
  .cls-7{
    fill:#ffdaea;
  }
  .cls-8{
    fill:#cfbbac;
  }
  .cls-9{
    fill:#7f1400;
  }
  .cls-10{
    fill:#e4dcd7;
  }
  .cls-11{
    fill:#d8e1ff;
  }
  .cls-12{
    fill:#8d3500;
  }
  .cls-13{
    fill:#49ecca;
  }
  .cls-14{
    fill:#3a0406;
  }
  .cls-15{
    fill:#f9c0a5;
  }
  .cls-16{
    fill:#003741;
  }
  .cls-2{
    stroke-width:2.22px;
  }
  .cls-2,.cls-3,.cls-4,.cls-5,.cls-6{
    stroke:#000;stroke-miterlimit:10;
  }
  .cls-3{
    stroke-width:2.36px;
  }
  .cls-4{
    stroke-width:3.5px;
  }
  .cls-17{
    fill:#f9d1bd;
  }
  .cls-18{
    fill:#ffaf00;
  }
  .cls-19{
    fill:#fbb004;
  }
  .cls-20{
    fill:#f6002c;
  }
  .cls-5{
    stroke-width:2.48px;
  }
  .cls-6{
    stroke-width:2.48px;
  }
}

.deltakerreisen-trekning-samlet {
  .cls-1,.cls-2,.cls-3,.cls-4,.cls-5,.cls-6,.cls-7,.cls-8,.cls-9,.cls-10,.cls-11,.cls-12{fill:none;}.cls-13{fill:#923f00;}.cls-14{fill:#fd7932;}.cls-15{fill:#ffdaea;}.cls-16{fill:#ffc1cf;}.cls-17{fill:#cfbbac;}.cls-18{fill:#7f1400;}.cls-19{fill:#e4dcd7;}.cls-20{fill:#d8e1ff;}.cls-21{fill:#ffa974;}.cls-22{fill:#8d3500;}.cls-23{fill:#49ecca;}.cls-24{fill:#3a0406;}.cls-25{fill:#f9c0a5;}.cls-2{stroke-width:1.93px;}.cls-2,.cls-3,.cls-4,.cls-5,.cls-6,.cls-7,.cls-8,.cls-9,.cls-10,.cls-11,.cls-12{stroke-miterlimit:10;}.cls-2,.cls-4,.cls-5,.cls-6,.cls-7,.cls-8,.cls-9,.cls-10,.cls-11,.cls-12{stroke:#000;}.cls-26{fill:#2e5ae2;}.cls-3{stroke:#003741;}.cls-3,.cls-4{stroke-width:2.3px;}.cls-27{fill:#003741;}.cls-5{stroke-width:2.15px;}.cls-6{stroke-width:2.15px;}.cls-7{stroke-width:2.07px;}.cls-8{stroke-width:2.05px;}.cls-9{stroke-width:2.05px;}.cls-10{stroke-width:2.01px;}.cls-28{fill:#d7cac3;}.cls-29{fill:#f9d1bd;}.cls-30{fill:#ffaf00;}.cls-31{fill:#fbb004;}.cls-32{fill:#f6002c;}.cls-11{stroke-width:3.13px;}.cls-12{stroke-width:2.42px;}
}