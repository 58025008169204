@mixin button-green-dark {
  --color-background: var(--color-petrol_1);
  --color-foreground: var(--color-teal_1);
}

@mixin button-green-light {
  --color-background: var(--color-teal_1);
  --color-foreground: var(--color-petrol_1);
}

.pill {
  color: var(--color-foreground);
  border-color: var(--color-foreground);
}

a.text-brand-blue {
  > span {
    text-decoration: underline;
  }


  &:hover,
  &:active,
  &:focus {
    > span {
      text-decoration: none;
    }
  }
}

.button {
  padding: 1rem 2rem;
  transition: background-color var(--button-transition) ease 0s;
  background-color: var(--color-background);
  color: var(--color-foreground);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  @include breakpoint("sm") {
    gap: 2rem;
  }

  @include breakpoint("4xl") {
    gap: 3rem;
  }

  .icon {
    display: flex;
    transition: transform var(--button-transition) ease 0s, margin var(--button-transition) ease 0s;

    svg {
      fill: var(--color-foreground);
    }


  }

  &.internal-button {
    @include breakpoint("xl") {
      font-size: 2.6rem;
      padding: 1.5rem 2.5rem;
    }

    @include breakpoint("4xl") {
      font-size: 3.2rem;
      padding: 2.5rem 3.5rem;
    }

    .icon {
      &.arrow-stroke {
        @include breakpoint("xl") {
          svg {
            width: 2.4rem;
          }
        }

        @include breakpoint("4xl") {
          svg {
            width: 3.2rem;
          }
        }
      }
    }
  }

  // &:hover,
  // &:active,
  // &:focus {
  //   .icon {
  //     margin-left: .6rem;
  //   }
  // }

  &.-primary,
  &.green {
    @include button-green-dark;

    &:hover,
    &:active,
    &:focus {
      @include button-green-light;

      .arrow-right {
        margin-left: .6rem;
      }
    }
  }

  &.-secondary,
  &.green-light {
    @include button-green-light;

    &:hover,
    &:active,
    &:focus {
      @include button-green-dark;

      .arrow-right {
        margin-left: .6rem;
      }
    }
  }

  &.-red {
    --color-foreground: var(--color-white);
    --color-background: var(--color-red_1);

    &:hover,
    &:active {
      --color-foreground: var(--color-red_1);
      --color-background: var(--color-pink_1);
    }
  }

  &.-round {
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;

    @include breakpoint(lg) {
      width: 6.4rem;
      height: 6.4rem;
    }
  }
}

.scrollTop {
  .button {
    position: fixed;
    bottom: 4rem;
    right: 2rem;
    z-index: 100;

    @include breakpoint(lg) {
      right: 4rem;
    }
  }
}

.button-text {
  --color-foreground: var(--color-petrol_1);
  color: var(--color-foreground);
  font-size: inherit;
  display: flex;
  gap: 1.5rem;

  .icon {
    transition: transform var(--button-transition) ease 0s;

//     @include breakpoint-below("lg") {
//       width: .95rem;
//
//       path {
//         stroke-width: 4;
//       }
//     }
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;

    .arrow-right {
      transform: translateX(.6rem);
    }
  }
}
