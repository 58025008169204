.meta {
  .social {
    .some-button {
      --button-size: 4.8rem;
      --color-foreground: var(--color-light-grey_1);
      --color-background: var(--color-yellow_1);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: var(--button-size);
      height: var(--button-size);
      background-color: var(--color-background);

      @include breakpoint(lg) {
        --button-size: 6.4rem;
      }

      &:hover,
      &:active,
      &:focus {
        outline: none;
        --color-foreground: var(--color-yellow_1);
        --color-background: var(--color-light-grey_1);
      }
    }
  }
}

.men-faen-da {
  display: none;
}
