@mixin hide-search-suggestions {
  opacity: 0.01;
  height: 0;
  overflow: hidden;
  pointer-events: none;
}

@mixin show-search-suggestions {
  opacity: 1;
  height: auto;
  overflow-y: auto;
}

.search-form {
  position: relative;
  background-color: var(--color-background);

  .search-form-el {// this is the form atm
    flex: auto;
    display: flex;
  }

  .submit-search {
    padding: 1rem 2rem;

    .icon {
      width: 2rem;
      height: 2rem;
    }
  }

  .inner-content {
    display: flex;
    gap: 0;
  }

  .search-field {
    width: 100%;
    border: 0px none;
    background: transparent;
    padding: 1rem 2rem;
    color: var(--color-foreground);
  }

  .search-suggestions {
    border-top: .1rem solid var(--color-petrol_1);
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--color-background);
    padding: 2rem;
    box-shadow: 0 .3rem .3rem rgba(var(--color-black-rgb), .16);
    max-height: 40vh;
    overflow-y: auto;
    z-index: 10;

    @include breakpoint(md) {
      padding: 3.5rem 4rem;
    }

    .items {
      display: grid;
    }

    .suggestion {
      display: flex;


      .title {
        flex: auto;
      }

      .suggestion-link {
        flex: auto;
        display: flex;
        align-items: center;
        gap: 2rem;
        padding: .4rem 0;

        &:hover,
        &:active,
        &:focus {
          .title {
            text-decoration: underline;
          }
        }
      }

      .pill {
        padding: .8rem 1rem;
        line-height: 1.2;
      }
    }

    &.-hide {
      @include hide-search-suggestions;
    }
  }

  // &:not(:focus-within) {
  //   .search-suggestions {
  //     @include hide-search-suggestions;
  //   }
  // }

  // &:focus-within {
  //   .search-suggestions {
  //     @include show-search-suggestions;
  //   }
  // }
}
