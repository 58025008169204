.jumbotron {
  .paragraph {
    h5 {
      @apply lg:text-3xl mb-5;
    }
  }
}

.component.jumbotron {

  h2 {
    font-size: 2.2rem;
    margin-bottom: 1.6rem;

    @include breakpoint("sm") {
      font-size: 3rem;
    }

    @include breakpoint("2xl") {
      font-size: 4rem;
    }

    @include breakpoint("4xl") {
      font-size: 5rem;
    }
  }

  .preamble {
    margin-bottom: 0;

    .lead {
      h2 {
        font-size: 1.9rem;
      }

      p {
        font-size: 2.2rem;
        line-height: 1.4;

        @include breakpoint("xl") {
          font-size: 2.8rem;
        }

        @include breakpoint("3xl") {
          font-size: 3.2rem;
        }

        @include breakpoint("4xl") {
          font-size: 4rem;
        }


       }
    }
  }

  .paragraph {

    h5 {
      font-size: 1.9rem;
      margin-bottom: 0;

      @media (min-width: 1024px){
          font-size: 2.4rem;
          margin-bottom: 1.6rem;
          margin-top: 14px;
      }

      @media (min-width: 1280px){
          font-size: 3.2rem;
          margin-bottom: 1.9rem;
      }
    }

    .paragraph-content {
      @include jumbotron-p-text;
    }

    p, ul, ol {
      & + p {
        margin-top: 5rem;
      }
    }
  }


  .internalButton,
  .externalButton {

    margin-top: 0;

    @media (min-width: 1024px){
        //margin-top: 2rem;
    }

    a.green, a.green-light {
        flex: 1 1 auto;
        max-width: 100%;

        svg {
          min-height: 1.9rem;
          @media (min-width: 1280px){
              min-height: 2.8rem;
          }
        }

        @media (min-width: 1280px){
            max-width: 100%;
        }
    }

  }
}
