.component.contact {
  .card {
    display: flex;
    flex-direction: column;
    align-content: start;
    padding: 5rem 2.5rem;
    background-color: var(--color-background);

    @include breakpoint("lg") {
      padding: 7rem 5rem;
    }

    .card-header {
      color: var(--color-foreground);
    }

    .card-content,
    .card-contact {
      color: var(--color-text);
    }

    .card-contact {
      > .phone {
        // display: grid;

//         .phone-item {
//           display: flex;
//           flex-direction: column;
//           gap: 1.6rem;
//
//           @include breakpoint(lg) {
//             flex-direction: row;
//           }
//         }
      }
    }

    .button-text {
      margin-top: auto;
      justify-self: end;
      --color-foreground: inherit;
      color: var(--color-foreground);
      text-decoration: none;

      &:hover,
      &:active,
      &:focus {
        // text-decoration: underline;

        .icon {
          transform: translateX(.4rem);
        }
      }
    }

    &.-dark-bg {
      --color-background: var(--color-petrol_1);
      --color-foreground: var(--color-teal_1);
      --color-text: var(--color-light-grey_1);
    }

    &.-light-bg {
      --color-background: var(--color-teal_1);
      --color-foreground: var(--color-petrol_1);
      --color-text: var(--color-black);
    }

  }
}
