.page-top {
  > .component-inner {
    > .inner-content {
      display: grid;
      gap: var(--column-gap);

      @include breakpoint(md) {
        grid-template-columns: 1fr 1fr;
      }

      @media (min-width: 1280px) {
        grid-template-columns: 2fr 1fr;
      }
    }
  }

  .search-form {
    --color-background: var(--color-light-grey_1);
    --color-foreground: var(--color-dark-grey_1);
  }
}
