.breadcrumb {
  a {
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
  }
}
