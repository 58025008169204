@mixin print-block-content {
  display: block;
  width: 80%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;

  // p {
  //   max-width: 60rem;
  //   margin-left: auto;
  //   margin-right: auto;
  // }
}

// @mixin print-style {
  .icon {
    max-width: 11rem;
    display: flex;

    svg {

    }

    &-red {
      @apply text-red-dark;
    }

    &-yellow {
      @apply text-orange;
    }

    &-green {
      @apply text-green-dark;
    }

    &-blue {
      @apply text-brand-blue;
    }
  }

  @import "variables";

  h1 {
    break-before: avoid;
  }

  html {
    // font-size: 62.5%;
  }
  body {
    padding: 0;
    margin: 0;
    font-size: 1.4rem;
    line-height: 1.4;
  }

  .lead {
    font-size: 1.8rem;
  }

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  ul, ol {
    list-style: inside;
  }

  ul li {
    list-style-type: disc;
  }

  ol li {
    list-style-type: decimal;
  }

  .paragraph {
    .subtitle {
      margin-bottom: 2rem;
      font-size: 2.5rem;
      break-after: avoid;
    }

    .-highlighted {
      padding: 3rem;
    }
  }

  .section.tips {
    .block-header {
      padding-top: 0;
      margin-bottom: 3rem;

      .title {
        margin-bottom: 2rem;
      }
    }
  }

  .component-inner {
    ul, ol, p {
      &:not(:first-child) {
        margin-top: .5em;
      }

      &:not(:last-child) {
        margin-bottom: .5em;
      }
    }
  }

  .site-header, .site-footer, .footer-logo, nav, form, iframe, .menu, .scroll-indicator, .section.related {
    display: none;
  }

  // .bg-red-light {
  //   background-color: map.get($theme-colors, "pink_1");
  // }

  main {
    // * {
    //   width: 100% !important;
    //   max-width: 100% !important;
    // }
  }

  .hero {
    .hero-wrapper {
      padding: 6rem 0 4rem;
    }

    .hero-header,
    .meta {
      > * {
        @include print-block-content();
      }
    }

    .meta {
      .buttons {
        display: none;
      }
    }
  }


  .main-content {
    padding-top: 4rem;
    padding-bottom: 4rem;

    > * {
      @include print-block-content();
    }

    > .section + .section,
    > .component + .component {
      margin-top: 4rem;
    }

    .tips {
      break-inside: avoid;

      .lead {
        font-size: inherit;
      }

      .tips-inner {
        padding: 3rem;
      }
    }

    .accordion {
      break-inside: avoid;

      .toggle {
        @apply font-SuisseIntlMedium;
        padding: 0;
        font-size: 2.5rem;
      }

      .inner {
        padding: 2rem 0;
        font-size: inherit;
      }
    }

    .quote {
      break-inside: avoid;
      padding: 3rem;

      .component-inner {
        display: flex;

        .content {
          flex: auto;
        }
      }

      .text {
        font-size: 2.5rem;
      }
    }
  }

  .flex {
    // display: block;

    > * {
      max-width: 100%;
    }
  }
// }
