.popup {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  --menu-transition: .5s;

  // transition: opacity var(--menu-transition) ease 0s, transform var(--menu-transition) ease 0s;

  > .overlay {
    z-index: 100;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(var(--color-black-rgb), .5);

//     &.fade-enter-active,
//     &.fade-leave-active {
//       transition: opacity var(--menu-transition) ease 0s;
//     }
//
//     &.fade-enter,
//     &.fade-enter-from,
//     &.fade-leave-to {
//       opacity: 0;
//       transition: opacity var(--menu-transition) ease 0s;
//     }
  }

  .message {
    z-index: 120;
    background-color: var(--color-white);
    padding: 2rem;
    width: 100%;
    max-width: 60rem;

    @include breakpoint(md) {
      padding: 4rem;
    }

    // @include slide-animation;
  }

  .overlay,
  .message {
    transition: opacity var(--menu-transition) ease 0s, transform var(--menu-transition) ease 0s;
    opacity: 1;
    transform: none;
  }

  &.popup-enter-active,
  &.popup-leave-active {
    transition: opacity var(--menu-transition) ease 0s, transform var(--menu-transition) ease 0s;
  }

  &.popup-enter,
  &.popup-enter-from,
  &.popup-leave-to {
    .overlay,
    .message {
      opacity: 0;
      transition: opacity var(--menu-transition) ease 0s, transform var(--menu-transition) ease 0s;
    }

    .message {
      transform: translateY(4rem);
    }
  }
}
