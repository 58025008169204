.footer {
  padding: 7rem var(--component-outer-spacing) 15rem;
  --row-gap: 3rem;

  @include breakpoint("lg") {
    padding: 15rem var(--component-outer-spacing);
  }

  .footer-nav {
    // flex flex-wrap flex-row
    display: flex;
    flex-wrap: wrap;
    max-width: var(--content-max-width);
    gap: var(--row-gap) var(--column-gap);
  }

  .bottom-links {
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    gap: .4rem 2rem;

    @include breakpoint("lg") {
      flex: none;
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  a {
    @apply font-SuisseIntlBook;

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
    }
  }
}

.footer-logo {
  // @apply bg-white p-8;
  padding-top: 3rem;
  padding-bottom: 3rem;

  // @include breakpoint("md") {
  //   padding-top: 0;
  //   padding-bottom: 0;
  // }

  .items {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    flex-wrap: wrap;

    @include breakpoint("md") {
      gap: 5rem;
    }

    @include breakpoint("xl") {
      gap: 8rem;
    }
  }

  .logo-wrapper {
    // flex: 0 1 25%;
    max-width: 25%;
    min-width: 15rem;

//     &.-img {
//       padding: 3rem 0;
//
//       @include breakpoint("md") {
//         padding: 5rem 0;
//       }
//     }
  }
}

