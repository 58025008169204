.app-wrapper {
  .hero,
  main,
  .site-footer {
    transition: opacity .5s ease 0s;
    opacity: 0;
  }

  &.-ready {
    .hero,
    main,
    .site-footer {
      opacity: 1;
    }
  }
}
