.dot-nav {
  position: relative;
  display: flex;
  justify-content: center;
  gap: .5rem;
  margin-top: 3rem;

  @include breakpoint("md") {
    display: none;
  }

  .dot {
    border-radius: 50%;
    border: .1rem solid var(--color-petrol_1);
    background-color: transparent;
    width: 1rem;
    height: 1rem;

    &.-active {
      border: .1rem solid var(--color-blue_1);
      background-color: var(--color-blue_1);
    }
  }
}
