.card,
.list-empty {
  --shuffle-transition: .4s;

  // &.-not-intersecting {
  //   transition: none !important;
  // }
  // &:not(.not-intersecting) {
  //   --shuffle-transition: .4s;
  // }
  -webkit-hyphens: auto;
  hyphens: auto;
  word-break: break-word;
}

//.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  --transition-delay-factor: 0;

  &:not(.not-intersecting) {
    transition: transform var(--shuffle-transition) ease calc(40ms * var(--transition-delay-factor)), opacity var(--shuffle-transition) ease calc(40ms * var(--transition-delay-factor));
  }

  &:nth-child(2n + 2) {
    --transition-delay-factor: 1;
  }

  &:nth-child(3n + 3) {
    --transition-delay-factor: 2;
  }

  &:nth-child(4n + 4) {
    --transition-delay-factor: 3;
  }
}

.list-enter,
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
 animations can be calculated correctly. */
.list-leave-to,
.list-leave-active {
  position: absolute;
}

.card {
  .read-more-link {
    margin-top: 2rem;
    font-size: 1.6rem;

    @media (min-width: 768px){
      font-size: 1.9rem;
    }
    @media (min-width: 1280px){
      font-size: 2.5rem;
    }
  }
//   &.-not-intersecting {
//     transition: none;
//
//     &.list-enter,
//     &.list-enter-from,
//     &.list-leave-to,
//     &.list-move,
//     &.list-enter-active,
//     &.list-leave-active {
//       opacity: 0;
//       transition: none;
//     }
//   }
}
