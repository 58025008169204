.InfoGraphic {
    padding-bottom: var(--vertical-block-spacing-lg);
    padding-top: var(--vertical-block-spacing-lg);

    .component-header {
        max-width: 530px;
    }

    .graphic-grid {
        margin-top: var(--vertical-block-spacing-md);
        display: grid;
        gap: 15rem 1rem;
        grid-template-columns: repeat(12, 1fr);

        @include breakpoint(lg) {
            gap: 10rem 3rem;
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        .item {
            position: relative;

            .heading {
                margin-bottom: 1.6rem;
                font-size: 2rem;
                text-align: center;

                @include breakpoint(md) {
                    font-size: 3.2rem;
                    margin-bottom: 3.5rem;
                }
            }

            .text {
                font-size: 1.6rem;
                text-align: center;

                @include breakpoint(md) {
                    font-size: 2.2rem;
                }
            }
        }

        .item.one {
            grid-column: 2 / span 10;

            @include breakpoint(lg) {
                grid-column: 1;
            }

            #arrow-one {
                bottom: -80px;
                left: calc(50% - 40px);
                position: absolute;
                transform: rotate(90deg);
                width: 80px;

                display: none;

                @include breakpoint(lg) {
                    bottom: auto;
                    width: 40%;
                    right: -20%;
                    top: 10%;
                    left: auto;
                    transform: rotate(0);
                    display: block;
                }

                @include breakpoint(2xl) {
                    top: 12%;
                }

                @include breakpoint(3xl) {
                    top: 14%;
                }
            }

            #arrow-eight {
                position: absolute;
                display: block;
                left: calc(50% - 20px);
                position: absolute;
                width: 40px;
                bottom: -40%;

                @include breakpoint(lg) {
                    display: none;
                }
            }
        }

        .item.two {
            grid-column: 2 / span 10;

            @include breakpoint(lg) {
                grid-column: 2;
            }

            #arrow-three {
                display: none;
                position: absolute;
                left: 86%;
                top: -8%;
                width: 140%;

                @include breakpoint(lg) {
                    display: block;
                    top: -12%;
                }

                @include breakpoint(xl) {
                    top: -14%;
                }

                @include breakpoint(3xl) {
                    top: -16%;
                }
            }

            #arrow-two {
                position: absolute;
                width: 40%;
                right: -20%;
                top: 4%;
                display: none;

                @include breakpoint(lg) {
                    top: 10%;
                    display: block;
                }

                @include breakpoint(2xl) {
                    top: 12%;
                }

                @include breakpoint(3xl) {
                    top: 14%;
                }
            }

            #arrow-six {
                display: block;
                position: absolute;
                bottom: -120px;
                width: 200px;

                @include breakpoint(lg) {
                    display: none;
                }
            }
        }

        .item.three {
            grid-column: span 6;

            @include breakpoint(lg) {
                grid-column: 3;
            }

            #arrow-four {
                position: absolute;
                width: 10%;
                bottom: -16%;
                left: 50%;
                transform: translateX(-50%);
                display: none;

                @include breakpoint(lg) {
                    display: block;
                }
            }
        }

        .item.four {
            grid-column: span 6;

            @include breakpoint(lg) {
                grid-column: 4;
            }

            #arrow-five {
                bottom: -42%;
                right: 50%;
                position: absolute;
                width: 46%;

                display: none;

                @include breakpoint(lg) {
                    display: block;
                }
            }
        }

        .item.one,
        .item.two,
        .item.three,
        .item.four {
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        .item:not(.last) {
            .illustration {
                margin-bottom: 25px;

                @include breakpoint(md) {
                    margin-bottom: 35px;
                }
            }
        }

        .item.last {
            background: var(--color-blue_2);
            grid-column: span 12;
            display: flex;
            padding: 4rem 4rem 4rem 6rem;
            flex-direction: column;
            align-items: center;

            @include breakpoint(lg) {
                grid-column: 2 / span 2;
            }

            @include breakpoint(xl) {
                flex-direction: row;
                align-items: flex-start;
            }

            #arrow-seven {
                display: block;
                position: absolute;
                top: -120px;
                width: 200px;
                left: calc(50% - 100px);

                @include breakpoint(lg) {
                    display: none;
                }
            }

            .illustration {
                @include breakpoint(xl) {
                    margin-right: 54px;
                }
            }

            .content {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }

            .link-button {
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-wrap: wrap;
                margin-top: 10px;
                color: #093640;

                @include breakpoint(lg){
                    margin-top: 44px;
                }
            }
        }
    }
}