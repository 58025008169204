
.hero {
  // overflow-x: hidden;
  position: relative;
  padding-left: var(--component-outer-spacing);
  padding-right: var(--component-outer-spacing);
  // @apply relative px-8;

  min-height: 70vh;

  .hero-column {
    gap: 2rem;

    @include breakpoint("md") {
      gap: 3rem;
    }

    @include breakpoint("lg") {
      // flex: 1 0 50%;
      grid-column: span 6;
      gap: 0 4rem;
    }
  }

  .scroll-indicator {
    display: none;
  }

  &:not(.superimposed) {
    .hero-column {
      &.column-left {
      //   @include breakpoint("sm") {
      //     grid-column: span 8;
      //   }

        @include breakpoint("xl") {
          grid-column: span 6;
          // max-width: 67rem;
        }

        @include breakpoint("2xl") {
          // grid-column: span 7;
          // max-width: 67rem;
        }

        .hero-icon {
          align-self: end;

          @include breakpoint-below("lg") {
            order: -1;
            align-self: start;
          }
        }
      }

      &.column-right {
        @include breakpoint("xl") {
          grid-column: 8 / span 5;
        }

        @include breakpoint("xl") {
          --col-count: 5;
        }

        .hero-content-inner {
          position: relative;
          padding: 5rem 2.5rem;
          @include grid-layout();
          --col-count: 4;

          > * {
            grid-column: span var(--col-count);
            z-index: 2;
          }

          @include breakpoint("sm") {
            --col-count: 6;
            padding: 5rem 3rem;
          }

          @include breakpoint("md") {
            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              width: 100vw;
              background-color: inherit;
              z-index: 1;
            }
          }

          @include breakpoint("xl") {
            --col-count: 5;
            padding: 11.5rem 0;

            > * {
              grid-column: 2 / span 4;
            }
          }
        }
      }
    }
  }

  &.content {


    @include and-bg-color-classes() {
      @include breakpoint-below("lg") {
        overflow: hidden;

        .hero-wrapper {
          padding-bottom: 0;
        }

        .column-right {
          position: relative;

          .hero-content-inner {
            z-index: 2;
          }

          &::before {
            content: "";
            position: absolute;
            width: 100vw;
            height: 100vh;
            left: 0;
            top: 0;
            z-index: 1;
            margin-top: 10rem;
            margin-left: calc(50% - 50vw);
            background-color: var(--color-white);
          }
        }
      }
    }
  }

  &.icon {
    .hero-column {
      &.column-left {
        @include breakpoint("sm") {
          grid-column: span 8;
        }

        @include breakpoint("lg") {
          grid-column: span 6;
          // grid-column: span 8;
          // max-width: 67rem;
        }

        @include breakpoint("2xl") {
          // noe med smalere ingress her
        }
      }

      &.column-right {
        @include breakpoint("sm") {
          grid-column: 9 / span 4;
        }

        // @include breakpoint("xl") {
        //   grid-column: span 4;
        // }
      }
    }
  }

  &.illustration {
    .column-right {
      .illustration {
        flex: auto;
        aspect-ratio: 1;
        flex-direction: column;

        svg {
          flex: 1 1 0%;
          margin: auto;
          max-height: 100%;
          max-width: 100%;
        }

        @include breakpoint-below("lg") {
          max-width: 40rem;
          margin-left: auto;
          margin-right: auto;
        }
      }
//       .illustration {
//         height: 90vw;
//         max-height: 80rem;
//         display: flex;
//
//
//         @include breakpoint("sm") {
//           height: auto;
//           max-height: 70vh;
//         }
//
//         svg {
//           // flex: auto;
//         }
//       }
    }
  }

  &.superimposed {
    .hero-column {
      &.column-left {
        @include breakpoint("lg") {
          // grid-column: span 8;
          // max-width: 67rem;
        }
      }

      &.column-right {
        @include breakpoint("xl") {
          grid-column: span 4;
        }
      }
    }
  }

  &.text_image {
    .breadcrumb {
      @include breakpoint("lg") {
        grid-column: span 6;

        @include breakpoint-below("xl") {
          font-size: 1.6rem;
        }
      }
    }

    @include breakpoint-below("lg") {
      display: flex;
      flex-direction: column;

      .hero-wrapper-image {
        position: relative;
        order: 2;
        height: auto;
        margin: 0 calc(50% - 50vw);
        width: 100vw;
        aspect-ratio: 1;

        img {
          object-fit: cover;
        }
      }

      .hero-wrapper {
        order: 1;
      }
    }

    .hero-wrapper-image {
      &::after {
        content: none;
      }

      @include breakpoint("lg") {
        left: 50%;
        width: 50%;
      }
    }

    .hero-column.column-left {
      @include breakpoint("lg") {
        grid-column: 1 / span 6;
      }

      @include breakpoint("xl") {
        grid-column: 1 / span 6;
      }

      @include breakpoint("2xl") {
        grid-column: 1 / span 6;
      }
    }
  }

  .hero-links {
    @include grid-layout();
    --row-gap: 1rem;
    --col-count: inherit;
    margin-top: 4rem;

    // @include breakpoint("sm") {
    //   --col-count: 4;
    // }

    @include breakpoint("sm") {
      --col-count: inherit;
    }

    @include breakpoint("md") {
      margin-top: 6rem;
    }

    @include breakpoint("xl") {
      --row-gap: 2rem;
      --col-count: 4;
      margin-top: 10rem;
    }

    .link {
      grid-column: span calc(var(--col-count) / 2);
    }
  }

}

@keyframes nudge {
  0% {
    transform: translateY(.3rem);
  }

  100% {
    transform: translateY(-.3rem);
  }
}

.Home {
  .hero {
    .scroll-indicator {
      display: flex;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      aspect-ratio: 1;
      justify-content: center;
      align-items: center;
      padding: 2rem;

      .icon {
        --color-foreground: var(--color-petrol_1);
        animation: .7s infinite ease alternate nudge;

        svg {
          width: 2.2rem;

          @include breakpoint("lg") {
            width: 3rem;
          }
        }
      }
    }
  }
}

.hero {
  .hero-wrapper {
    // @apply flex min-h-screen flex-col lg:flex-row content-center justify-end lg:justify-center text-black relative break-words;
    @apply break-words;
    max-width: var(--content-max-width);
    position: relative;
    display: grid;
    // min-height: 40vh;
    min-height: inherit;
    gap: var(--column-gap);
    width: 100%;

    @include breakpoint(lg) {
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }

    > .breadcrumb {
      align-self: end;

      @include breakpoint(lg) {
        grid-column: span 8;
      }
    }

    > .hero-column {
      // gap: 2rem;

      .meta {
        // margin-top: auto;
        display: grid;
        // gap: 4rem;

        .meta-details {
          display: flex;
          gap: 3rem;
          flex-direction: column;

          @include breakpoint(sm) {
            flex-direction: row;
            justify-content: space-between;
          }

          .buttons {
            // gap: 1.6rem;
            .icon {
              width: 2.4rem;
              height: 2.4rem;
            }
          }

          .published,
          .buttons {
            display: grid;
            gap: .2em;

            > p {
              margin: 0;
            }
            // grid lg:grid-cols-1 gap-x-8 gap-y-4 mb-4
          }
        }

        .social {
          & + .meta-details {
            margin-top: 4rem;

            @include breakpoint(lg) {
              margin-top: 6.4rem;
            }

            @include breakpoint('2xl') {
              margin-top: 12rem;
            }
          }
        }
      }



      .search-form {
        flex: auto;
        --color-background: var(--color-white);
      }
    }

    &-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;

      .bg-image {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      // @apply h-screen w-screen absolute z-0 inset-0;
    }
  }

  .hero-header {
    --row-gap: 2rem;

    @include grid-layout();

    @include breakpoint("sm") {
      --col-count: 6;
    }

    @include breakpoint("lg") {
      --col-count: 8;
    }



    > * {
      margin: 0;

      grid-column: span var(--col-count);
    }

    @include breakpoint("lg") {
      --row-gap: 3rem;
    }

    .lead {
      @include breakpoint("xl") {
        // grid-column: span 6;
      }
    }

    h1,
    h3 {
      @apply mb-0;
    }

    h1 {
      + h3 {
        @apply mt-8;
      }
    }

    .label {
      + h1 {
        @apply mt-6;
      }
    }
  }

  &-inner {

    h1 {
      @apply mb-9;
    }

    .label {
      @apply text-xl mb-6;
    }

    .lead {
      @apply text-xl lg:text-2xl 2xl:text-4xl leading-snug lg:leading-snug xl:leading-snug;
    }
  }

  &-aside {
    > *:empty {
      display: none;
    }

    > *:not(:nth-last-child(1)) {
      @apply mb-6;
    }
  }

  &.content {
    .hero-inner {
      @apply flex-col lg:flex-row;
    }
  }

  &.icon {
    &,
    .hero-wrapper {
      @apply min-h-0;
    }
    .hero-wrapper {
      @include breakpoint(lg) {
        padding-top: 22rem;
      }
      // @apply lg:pt-80;
    }
  }

  &.illustration {
    .hero-inner {
       header {
        @apply xl:w-1/2;
      }

      .hero-aside {
        @apply flex-1;
      }
    }
  }
}

.Home {
  .hero {
    min-height: 80vh;
    display: flex;
  }
}

.ResourceMain {
  .hero {
    min-height: 40vh;

    &-wrapper {

      > .hero-column {

      }
    }
  }
}

// .Article {
  .hero.text_image {
    .breadcrumb {
      @include breakpoint("lg") {
        grid-column: span 6;

        @include breakpoint-below("xl") {
          font-size: 1.6rem;
        }
      }
    }

    @include breakpoint-below("lg") {
      display: flex;
      flex-direction: column;

      .hero-wrapper-image {
        position: relative;
        order: 2;
        height: auto;
        margin: 0 calc(50% - 50vw);
        width: 100vw;
        aspect-ratio: 1;

        img {
          object-fit: cover;
        }
      }

      .hero-wrapper {
        order: 1;
      }
    }

    .hero-wrapper-image {
      &::after {
        content: none;
      }

      @include breakpoint("lg") {
        left: 50%;
        width: 50%;
      }
    }


    .hero-column.column-left {
      @include breakpoint("lg") {
        grid-column: 1 / span 6;
      }

      @include breakpoint("xl") {
        grid-column: 1 / span 6;
      }

      @include breakpoint("2xl") {
        grid-column: 1 / span 6;
      }
    }
  }
// }

.hero-link {
  grid-column: span 12;
  width: fit-content;

  .link-button {
    display: flex;
    align-items: center;
  }

  svg {
    margin-left: 10px;
  }
}
