// @mixin body-font-size {
//   font-size: 1.6rem;
//
//   @include breakpoint("lg") {
//     font-size: 2.2rem;
//   }
//
//   @include breakpoint("4xl") {
//     font-size: 2.5rem;
//   }
// }
//
// @mixin accordion-title-font-size {
//   font-size: 2.2rem;
//
//   @include breakpoint("lg") {
//     font-size: 2.5rem;
//   }
//
//   @include breakpoint("2xl") {
//     font-size: 3.2rem;
//   }
//
//   @include breakpoint("4xl") {
//     font-size: 4rem;
//   }
// }
//
// @mixin jumbotron-p-text {
//   @include body-font-size();
// }
//
// @mixin block-heading-font-size {
//   font-size: 2.2rem;
//
//   @include breakpoint("lg") {
//     // font-size: 2.6rem;
//     font-size: 3.2rem;
//   }
//
//   @include breakpoint("2xl") {
//     font-size: 3.2rem;
//   }
//
//   @include breakpoint("4xl") {
//     font-size: 4rem;
//   }
// }
//
// @mixin post-item-title-size {
//   font-size: 2rem;
//
//   @include breakpoint("xl") {
//     font-size: 2.5rem;
//   }
//
//   @include breakpoint("2xl") {
//     font-size: 2.8rem;
//   }
//
//   @include breakpoint("4xl") {
//     font-size: 3.2rem;
//   }
// }
//
// @mixin post-item-text-size {
//   font-size: 1.6rem;
//
//   @include breakpoint("lg") {
//     font-size: 1.8rem;
//   }
//
//   @include breakpoint("4xl") {
//     font-size: 2.2rem;
//   }
// }

body {
  @apply font-SuisseIntlBook;
  line-height: 1.5;
  @include body-font-size();
}

.section,
.component {
  // @apply break-words;
  hyphens: auto;
  -webkit-hyphens: auto;
  overflow-wrap: anywhere;
  -webkit-hyphenate-limit-before: 6;
  -webkit-hyphenate-limit-after: 6;
  hyphenate-limit-chars: 10 6 6;
}

.accordion-item-details,
.preamble,
.paragraph {
  a {
    @apply underline hover:no-underline;
  }
}

h1 {
  font-size: 3rem;

  @include breakpoint("sm") {
    font-size: 4rem;
  }

  @include breakpoint("2xl") {
    font-size: 5rem;
  }

  @include breakpoint("4xl") {
    font-size: 6rem;
  }
}

h2 {
  @include block-heading-font-size();
}

h1,
h2 {
  @apply font-SuisseIntlBold mb-8;
}

h1,
h2 {
  line-height: 1.2;
}

h3,
h4,
h5,
h6 {
  line-height: 1.3;
}

h3,
h4,
.lead {
  @apply font-SuisseIntlMedium;

  &:not(.medium) {
    @apply font-SuisseIntlRegular;
  }

  &.book {
    @apply font-SuisseIntlBook;
  }

  &.bold {
    @apply font-SuisseIntlBold;
  }
}

.hero {
  .lead {
    @apply text-xl xl:text-2xl 2xl:text-4xl;
  }
}

.component {
  &.anchor {
    @apply font-SuisseIntlRegular;
    font-size: 2.2rem;
  }

  &.enhanced,
  &.feature,
  &.card.single,
  &.related {
    .block-heading {
      @include block-heading-font-size;
    }
  }

  &.card.multiple {
    .title,
    .subtitle {
      @include block-heading-font-size;
    }
  }

  &.description {
    .article-item {
      .column-title {
        @include post-item-title-size-sm();
      }

      .excerpt,
      .button-text {
        @include post-item-text-size();
      }
    }
  }

  &.related {
    .article-link {
      .article-title {
        @include post-item-title-size();
      }

      .link-btn {
        @include breakpoint-between("lg", "xl") {
          font-size: 1.6rem;
        }
      }
    }
  }
}

.paragraph,
.accordion-item-details {
  h4 {
    @apply font-SuisseIntlBold text-3xl mb-5;
  }

  p,
  ul,
  ol {
    &:not(:nth-last-child(1)) {
      @apply mb-6;
    }
  }

  .subtitle {
    @apply font-SuisseIntlBold;
  }

  ul, ol {
    list-style: inside;

    li {
      & + li {
        margin-top: .4em;
      }
    }
  }

  ul li {
    list-style-type: disc;
  }

  ol li {
    list-style-type: decimal;
  }

  a {
    @apply underline hover:no-underline;
  }
}


.search-form {
  strong {
    @apply font-SuisseIntlBook;
  }
}

.site-footer {
  .footer-nav {
    font-size: 1.4rem;

    @include breakpoint("lg") {
      font-size: 2rem;
    }

    @include breakpoint("4xl") {
      font-size: 2.2rem;
    }
  }

  .column-title {
    @apply font-SuisseIntlBold mb-4 xl:mb-6;

    font-size: 2rem;

    @include breakpoint("xl") {
      font-size: 2.4rem;
    }

    @include breakpoint("4xl") {
      font-size: 2.8rem;
    }
  }

  .bottom-links {
    font-size: 1.2rem;
  }
}

.linkButtonsBlock {
  .block-link {
    font-size: 2.2rem;

    @include breakpoint("lg") {
      font-size: 2.5rem;
    }

    @include breakpoint("4xl") {
      font-size: 3.2rem;
    }
  }
}
