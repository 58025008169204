@layer base {

  html {
    font-size: 62.5%;
  }

  i {
    font-style: normal;
  }

  html,
  body {
    @apply h-full;
    color: var(--color-black);
  }

  main {
    // min-height: 100vh;
  }

  body {
    @apply m-px bg-white;
     // lg:text-lg xl:text-xl 2xl:text-2xl;
    // @include body-font-size();
    // font-size: 16px;
    // @apply font-SuisseIntlBook;
    // line-height: 1.5;

    padding-top: 9.6rem;
  }

  h3,
  h4 {
    @apply mb-4 xl:mb-6;
  }

  p {
    @apply mb-4;

    &:nth-last-of-type(1) {
      @apply mb-0;
    }
  }

  h1 {
    // @apply text-2xl xl:text-4xl 2xl:text-6xl;
  }

//   h2 {
//     @apply text-xl xl:text-2xl 2xl:text-5xl;
//   }
//
//   h3 {
//     @apply text-xl xl:text-2xl 2xl:text-4xl;
//   }

  // h4 {
    // @apply text-lg xl:text-xl 2xl:text-3xl;
  // }

  // h5 {
    // @apply font-SuisseIntlBold text-lg xl:text-xl 2xl:text-2xl;
  // }

  pre {
    @apply whitespace-pre-wrap;
  }

  strong {
    @apply font-SuisseIntlBold;
    font-weight: normal;
  }

  .lead {
    @apply font-SuisseIntlRegular text-lg xl:text-xl 2xl:text-2xl;
    line-height: 1.4;
  }

  .app-wrapper {
    @apply grid min-h-screen relative grid-rows-layout grid-cols-full; // overflow-x-hidden
  }
}

#rbup {
  .cls-1 {
    fill: #c2cff4;
  }

  .cls-2 {
    fill: #728ee6;
  }

  .cls-3 {
    fill: #c21536;
  }

  .cls-4 {
    fill: #e31836;
  }

  .cls-5 {
    fill: #231f20;
  }

  .cls-6 {
    fill: #355edb;
  }
}

address {
  font-style: normal;
}
