.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  padding: 0;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  border: 0;
}

.skip-link {
  position: absolute;
  z-index: 100;
  left: 0;
  padding: 2rem;
  transform: translateX(-120%);
  transition: transform var(--menu-transition) ease 0s;
  opacity: 0;
  background-color: var(--color-white);
  box-shadow: 0 0 1.25rem 0 rgba(36,36,36,.25);
  color: var(--color-black);
  text-decoration: underline;

  &:focus {
    transform: translateX(0);
    outline: none;
    opacity: 1;
  }
}

.non-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: absolute;
  margin-left: -99999px;
  width: 0;
  height: 0;
  overflow: hidden;

  &:hover,
  &:active,
  &:focus {
    & + .checkbox-toggle-label {
      text-decoration: underline;
    }
  }
}
