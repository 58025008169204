.accordion {
  margin-bottom: 0;

  .accordion-title {
    @include accordion-title-font-size();
  }

  .accordion-item {
    margin-bottom: 0;

    .item-header {

    }

    .toggle {
      padding-bottom: 1.6rem;
      padding-top: 1.6rem;

      .title {
        @apply font-SuisseIntlRegular;
        @include accordion-title-font-size();
        //  text-xl lg:text-2xl 2xl:text-4xl
      }

      @include breakpoint("4xl") {
        padding-bottom: 3rem;
        padding-top: 3rem;
      }

      .icon {
        transition: transform var(--button-transition) ease 0s;

        @include breakpoint("lg") {
          width: 1.8rem;

          path {
            stroke-width: 3;
          }
        }

        @include breakpoint("2xl") {
          width: 2.2rem;

          svg {
            width: 100%;
          }
        }

        svg {
          transition: inherit;
        }
      }

      &:hover,
      &:active,
      &:focus {
        // outline: none;

        .icon {
          transform: translateY(.6rem);
        }
      }
    }

    &.is-active {
      .toggle {
        &:hover,
        &:active,
        &:focus {
          // .icon {
          //   transform: translateY(-.6rem);
          // }
        }
      }
    }

    .item-details {
      transition: max-height var(--button-transition) ease 0s;
      overflow: hidden;

      .inner {
        background-color: #fff;
        transition: transform var(--button-transition) ease 0s, opacity var(--button-transition) ease 0s;
        opacity: 0;
        transform: translateY(-3rem);

        > * {
          &:not(:nth-last-child(1)) {
            @apply mb-4;
          }
        }
      }
    }

    &.-closed {
      .item-details {
        max-height: 0;

        .inner {
          opacity: 0;
          transform: translateY(-3rem);
        }
      }
    }

    &.-open {
      &:not(.-closing) {
        .item-details {
          .inner {
            opacity: 1;
            transform: translateY(0rem);
          }
        }

        .toggle {
//           .icon.arrow {
//             transform: rotate(180deg);
//           }
//
//           &:hover,
//           &:active,
//           &:focus {
//             .icon.arrow {
//               transform: rotate(180deg) translateY(.4rem);
//             }
//           }
        }

        .item-details {
          max-height: var(--max-height);
        }
      }

      &.-closing {
        .item-details {
          max-height: 0px;
        }
      }
    }

    .slide-enter-active,
    .slide-leave-active {
      transition: transform var(--button-transition) ease 0s, opacity var(--button-transition) ease 0s;
    }

    .slide-enter,
    .slide-leave-to {
      opacity: 0;
      transform: translateY(-2rem);
    }
  }
}

// .accordion {
//
//   &-title {
//     @apply mb-12;
//   }
//
//   &-item {
//
//     &.is-active {
//       .accordion-item-trigger {
//         svg {
//           @apply rotate-180;
//         }
//       }
//     }
//
//     &-title-text {
//       @apply font-SuisseIntlRegular text-xl lg:text-2xl 2xl:text-4xl;
//     }
//
//     &-details-inner {
//       > * {
//         &:not(:nth-last-child(1)) {
//           @apply mb-4;
//         }
//       }
//     }
//   }
// }
//
// .accordion {
//   margin-bottom: 0;
//   .accordion-item-details {
//     .accordion-item-details-inner {
//       background-color: #fff;
//     }
//   }
//
//   .accordion-item:not(.is-active) {
//     .accordion-item-title {
//
//       svg {
//         transition: all 100ms ease-out;
//       }
//
//       &:hover {
//         svg {
//           margin-top: 1.6rem;
//         }
//       }
//     }
//   }
//
//   .accordion-item {
//
//     margin-bottom: 0;
//     @media (min-width: 1024px){
//         margin-bottom: 3.2rem;
//     }
//
//     .accordion-item-trigger {
//       padding-bottom: 1.6rem;
//       padding-top: 1.6rem;
//       @media (min-width: 1024px){
//
//           padding-bottom: 3.2rem;
//       }
//     }
//   }
// }
