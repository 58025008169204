.component.VideoText {
    padding-bottom: var(--vertical-block-spacing-lg);
    padding-top: var(--vertical-block-spacing-xl);

    .component-inner {
        display: grid;
        grid-gap: var(--row-gap) 1rem;
        grid-template-columns: repeat(12, 1fr);

        @include breakpoint(lg) {
            grid-gap: var(--row-gap) var(--column-gap);
            grid-template-columns: repeat(12, minmax(0, 1fr));
        }

        .component-video-text-text {
            grid-column: span 12;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include breakpoint(lg) {
                grid-column: 1 / span 4;
            }
        }

        .component-video-text-video {
            grid-column: span 12;
            padding-top: 20px;

            @include breakpoint(lg) {
                padding-top: 0;
                grid-column: 6 / span 7;
            }
        }
    }
}