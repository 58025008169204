.definition-list {
  // display: flex;
  // flex-wrap: wrap;
  // gap: .4rem 1.6rem;
  display: block;

  .label {
    // flex: none;
    @apply font-SuisseIntlBold;
  }

  .value {
    // flex: 1 1 auto;
  }

  .label,
  .value {
    display: inline;
  }

  > .list-item {
//     flex: 1 0 100%;
//     display: flex;
//     flex-direction: column;
//     gap: .4rem 1.6rem;
//
//     @include breakpoint(lg) {
//       flex-direction: row;
//       gap: .4rem 1.6rem;
//     }
    display: block;
  }
}
