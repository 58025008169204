.dropdown {
  .dropdown-header {
    .dropdown-toggle {
      padding: .8rem;

      > .icon {
        width: 1.6rem;
        height: 1.6rem;
        position: relative;
        display: flex;

        &::before,
        &::after {
          content: "";
          width: 100%;
          height: .2rem;
          border-radius: .2rem;
          position: absolute;
          top: 50%;
          left: 0;
          background-color: var(--color-petrol_1);
        }

        &::before {
          transform: translate(0, -50%);
        }

        &::after {
          transform: translate(0, -50%) rotate(90deg);
        }
      }
    }
  }

  .dropdown-content {
    position: relative;
    overflow: hidden;
    height: 0;

    > .dropdown-item {
      // opacity: 0;
      opacity: 1;
      transition: opacity .15s ease 0s, transform .15s ease 0s;
      // transform: translateY(-2rem);
    }
  }

  .slide-enter,
  .slide-leave-to {
    transition: opacity .15s ease 0s, transform .15s ease 0s;

    > .dropdown-item {
      opacity: 0;
      transition: opacity .15s ease 0s, transform .15s ease 0s;
      transform: translateY(-2rem);
    }
  }

  &.-open {
    .dropdown-header {
      .dropdown-toggle {
        > .icon {
          &::after {
            transform: translate(0, -50%);
          }
        }
      }
    }

    > .dropdown-content {
      height: auto;

      // > .dropdown-item {
      //   opacity: 1;
      //   transform: translateY(0);
      // }
    }

    & + .dropdown {
      margin-top: 2rem;
    }
  }

  @include slide-animation;

  .dropdown-item {
    @include slide-animation;
  }
}
