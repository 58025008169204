.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;

  svg {
    display: flex;
    flex: 1;
    fill: var(--color-foreground);

    .color-stroke {
      stroke: var(--color-foreground);
    }
  }

  &.icon-x {
    position: relative;
    width: 1.2rem;
    height: 1.2rem;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      height: .2rem;
      width: 100%;
      background-color: var(--color-foreground);
      border-radius: .2rem;
      overflow: hidden;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  &.search {
    width: 1.62rem;
    height: 1.88rem;
  }

  &.arrow {
    width: 1.5rem;
    height: 1.5rem;

    &.arrow-large {
      width: 3.41rem;
      height: 2.53rem;
    }

    &.arrow-down {
      svg {
        transform: rotate(90deg);
      }
    }

    &.arrow-up {
      svg {
        transform: rotate(-90deg);
      }
    }

    &.arrow-left {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &.arrow-stroke {
    aspect-ratio: 25.3 / 34.1;


    svg {
      overflow: visible;
      width: 100%;
      width: 1.3rem;
      flex: none;

      // path {
      //   stroke-width: 4;
      // }

      // @include breakpoint("md") {
      //   path {
        // stroke-width: 3;
      // }
      // }
    }

//     @include breakpoint("lg") {
//       width: 1.8rem;
//     }
//
//     @include breakpoint("2xl") {
//       width: 2.2rem;
//     }

    &.arrow-up {
      svg {
        transform: rotate(180deg);
      }
    }

    &.arrow-down {
      svg {
        transform: rotate(0);
      }
    }

    &.arrow-left {
      svg {
        transform: rotate(90deg);
      }
    }

    &.arrow-right {
      svg {
        transform: rotate(-90deg);
      }
    }
  }
}
