.component.team {
  .team-item {
    --row-gap: 2rem;
    @include grid-layout();

    .image-column,
    .text-column {
      grid-column: span var(--col-count);

      @include breakpoint("sm") {
        grid-column: span calc(var(--col-count) / 2);
      }
    }

    .image-column {
      @include breakpoint("lg") {
        grid-column: span 5;
      }

      @include breakpoint("2xl") {
        grid-column: 2 / span 4;
      }
    }

    .text-column {
      display: grid;
      align-content: end;
      row-gap: 1rem;

      .name {
        font-size: 2rem;

        @include breakpoint("2xl") {
          font-size: 2.5rem;
        }
      }

      .contact-info {
//         font-size: 1.6rem;
//
//         @include breakpoint("lg") {
//           font-size: 2.2rem;
//         }
//
//         @include breakpoint("4xl") {
//           font-size: 2.5rem;
//         }
      }

      @include breakpoint("lg") {
        row-gap: 1.2rem;
        grid-column: span 7;
      }

      @include breakpoint("2xl") {
        grid-column: span 6;
      }
    }
  }
}
