.component.resources {

  .spinner {
    display: flex;
    position: relative;

    &:not(.-rendering) {
      display: none;
    }
  }

  .component-inner {
    position: relative;
    display: grid;
    // display: flex;
    // flex-wrap: wrap;
    gap: var(--column-gap);

    @include breakpoint(md) {
      // flex-wrap: nowrap;
      grid-template-columns: repeat(3, minmax(0, 1fr));

      > .resources-wrap {
        grid-column: span 2;
      }
    }

    @include breakpoint(xl) {
      // flex-wrap: nowrap;
      grid-template-columns: repeat(4, minmax(0, 1fr));

      > .resources-wrap {
        grid-column: span 3;
      }
    }

    .button.toggle-filter {
      width: 100%;
      justify-content: center;

      @include breakpoint(md) {
        display: none;
      }

      .icon {
        flex: none;
      }
    }

    .remove-filters {
      align-self: flex-end;
      margin-left: auto;
    }

    > .overlay {
      z-index: 100;
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      background-color: rgba(var(--color-black-rgb), .5);

      &.fade-enter-active,
      &.fade-leave-active {
        transition: opacity var(--menu-transition) ease 0s;
      }

      &.fade-enter,
      &.fade-enter-from,
      &.fade-leave-to {
        opacity: 0;
        transition: opacity var(--menu-transition) ease 0s;
      }

      @include breakpoint(md) {
        display: none;
      }
    }

    > .filter {
      --color-foreground: var(--color-black);
      --color-background: var(--color-white);
      width: 100vw;
      max-width: 32rem;
      overflow-y: auto;
      margin-left: 0;
      height: 100vh;
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 210;
      background-color: var(--color-background);
      transform: translateX(-100%);
      transition: transform var(--menu-transition) ease 0s;

      &.-in {
        transform: translateX(0);
      }

      .filter-header,
      .dropdown-header {
        padding: 1rem 2rem;
        color: var(--color-foreground);
        background-color: var(--color-background);
      }

      .filter-header {
        --color-foreground: var(--color-white);
        --color-background: var(--color-petrol_1);
      }

      .dropdown-header {
        --color-foreground: var(--color-petrol_1);
        --color-background: var(--color-teal_2);
      }

      > .dropdown {
        display: flex;
        flex-direction: column;

        &.-open {
          gap: 1rem;
        }

        &:not(.-open) {
          & + .dropdown {
            border-top: .1rem solid var(--color-petrol_1);
          }
        }
      }

      .submenu-items {
        display: flex;
        flex-direction: column;
        // gap: 1rem;

        .filter-item {
          flex: none;
          gap: 0rem 1rem;

          > .checkbox {
            @supports(-webkit-appearance: none) or (-moz-appearance: none) {
              -webkit-appearance: none;
              -moz-appearance: none;
              border: .2rem solid var(--color-foreground);
              background-color: transparent;
              position: relative;
              width: 1.6rem;
              height: 1.6rem;

              &::before,
              &::after {
                content: "";
                width: 2rem;
                height: .2rem;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }

              &:checked {
                &::before,
                &::after {
                  background-color: var(--color-foreground);
                }

                &::before {
                  transform: translate(-50%, -50%) rotate(-45deg);
                }

                &::after {
                  transform: translate(-50%, -50%) rotate(45deg);
                }
              }
            }
          }

          &.-selected {
            > label {
              font-family: "Suisse Intl Bold", Helvetica, Arial, sans-serif;
            }
          }

          // &.-open {
          //   // gap: 1rem;
          // }

          .sub {
            padding-left: 2rem;

            .sub-item {
              padding: .5rem 0;
            }
          }

          .checkbox-toggle-label {
            padding: 1rem 0;
          }

          &.dropdown {
            // .checkbox-toggle-label {
            //   padding: 1rem 0;
            // }

            .dropdown-toggle {
              padding: 1rem;
              display: flex;

              .icon {
                display: flex;

                svg {
                  transition: transform var(--menu-transition) ease 0s;
                }
              }
            }

            .non-checkbox {
              &:focus {
                & + .dropdown-toggle {
                  background-color: var(--color-teal_2);
                }
              }
            }
          }
        }
      }

      @include breakpoint(md) {
        position: relative;
        flex: 1 0 25%;
        max-width: none;
        z-index: 1;
        transform: translateX(0);
        width: auto;
        height: auto;
        overflow-y: hidden;
        // margin-left: 0;
      }

      .close-filter {
        margin: 2rem;
      }
    }

    > .resources-wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 2.5rem;

      @include breakpoint(md) {
        flex: 1 1 75%;
      }
    }
  }

  .component-meta {
    margin-bottom: 1rem;
    display: flex;
    flex: none;
    width: 100%;
    gap: 2rem;

    .filter-terms {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      > .filter-term {

      }
    }

    .pill {
      --color-foreground: var(--color-petrol_1);
      background-color: var(--color-white);
      color: var(--color-foreground);
      padding: .6rem 1rem .6rem 1.8rem;
      display: flex;
      align-items: center;
      gap: 1rem;

      .remove-term {
        border-radius: 50%;
        padding: .5rem;
        overflow: hidden;

        &:hover,
        &:active {
          // border
        }
      }
    }

    .pill-button {
      &:hover,
      &:active,
      &:focus {
        border-color: var(--color-teal_1);
        background-color: var(--color-teal_1);
        // border
      }
    }

    .slide-move,
    .slide-enter,
    .slide-enter-from,
    .slide-leave-to {
      transition: opacity var(--menu-transition) ease 0s, transform var(--menu-transition) ease 0s;
    }

    .slide-enter,
    .slide-enter-from,
    .slide-leave-to {
      opacity: 0;
      transform: translateY(1rem);
    }

    .slide-leave-to,
    .slide-leave-active {
      position: absolute;
    }

    .remove-filters {
      display: flex;
      align-items: center;
      gap: 1rem;
      white-space: nowrap;
    }
  }

  .resources-list {
    position: relative;
    display: grid;
    gap: 2rem;

    @include breakpoint(sm) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include breakpoint(lg) {
      gap: var(--column-gap);
    }

    @include breakpoint(xl) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}
