.quote {
  @apply py-20;


  blockquote {
    @apply mx-auto flex flex-wrap gap-8 lg:gap-20 items-center lg:justify-center;
  }

  // h2 {
  //   @apply font-SuisseIntlMedium mb-4 lg:mb-8 leading-snug;
  // }

  .text {
    @apply font-SuisseIntlMedium;

    line-height: 1.3;

    font-size: 2rem;

    @include breakpoint("sm") {
      font-size: 2.6rem;
    }

    @include breakpoint("lg") {
      font-size: 3.5rem;
    }

    @include breakpoint("xl") {
      font-size: 4rem;
    }

    @include breakpoint("4xl") {
      font-size: 5rem;
    }
  }

  cite {
    display: inline-block;
    @apply text-lg lg:text-xl;
    margin-top: 2rem;

    @include breakpoint("lg") {
      margin-top: 3.5rem;
    }
  }

  figure {
    @apply -order-1 lg:-order-none;
  }

  .icon {
    max-width: 100%;
  }
}
