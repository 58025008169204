

.hero {
  z-index: 15;
}

body {
  @include breakpoint("lg") {
    --vertical-block-spacing-sm: 2.5rem;
    --vertical-block-spacing-md: 4rem;
    // --vertical-block-spacing-lg: 10rem;
    --vertical-block-spacing-lg: 8rem;
    // --vertical-block-spacing-xl: 15rem;
    --vertical-block-spacing-xl: 11.5rem;
    --component-outer-spacing: 8rem;
  }

  @include breakpoint("2xl") {
    --vertical-block-spacing-md: 7rem;
    --vertical-block-spacing-lg: 11.5rem;
    // --vertical-block-spacing-xl: 20rem;
    --vertical-block-spacing-xl: 15rem;
  }

  @include breakpoint("4xl") {
    --component-outer-spacing: 4rem;
  }
}

.section {
  // @apply px-8;
  padding-left: var(--component-outer-spacing);
  padding-right: var(--component-outer-spacing);

  &.quote {
    blockquote {
      @apply max-w-screen-xl;
    }
  }

  &.related {
    &.extended {
      .article-link {

        .post-image-container {
          width: 100%;
        }

        img {
          aspect-ratio: 1 / 1;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.component {
  // @apply px-8 py-8;
  padding-left: var(--component-outer-spacing);
  padding-right: var(--component-outer-spacing);

  &.login {
    padding-top: var(--vertical-block-spacing-xl);
    padding-bottom: var(--vertical-block-spacing-xl);

    .component-inner {
      --row-gap: var(--vertical-block-spacing-md);
      @include grid-layout();

      .block-header,
      .login-form {
        @include breakpoint("sm") {
          grid-column: span 6;
        }
      }

      .block-header {
        // @include breakpoint("lg") {
        //   grid-column: span 7;
        // }
      }

      .login-form {
        @include breakpoint("lg") {
          grid-column: 8 / span 5;
        }

        @include breakpoint("2xl") {
          grid-column: 9 / span 4;
        }
      }
    }
  }

  &.linkButtonsBlock {
    margin-top: var(--vertical-block-spacing-lg);
    margin-bottom: var(--vertical-block-spacing-lg);

    > .component-inner {
      max-width: var(--content-max-width);
      margin-left: auto;
      margin-right: auto;
      display: grid;
    }

    .buttons-container {
      @include grid-layout();
      --row-gap: var(--vertical-block-spacing-md);

      .block-link-item {
        @include breakpoint("sm") {
          grid-column: span 6;
        }

        @include breakpoint("lg") {
          grid-column: span 4;
        }
      }
    }
  }

  &.superimpossed {
    @apply py-28 lg:py-48;
  }

  .component-inner {
    @apply mx-auto;
    max-width: var(--content-max-width);
    // max-w-screen-2xl
  }

  &.anchor {
    @apply text-gray-light z-10 bg-brand-green px-8 xl:py-12 4xl:sticky 4xl:top-0 mb-0;
  }

  &.category {
    @apply py-20 2xl:py-48;
  }

  &.accordion,
  &.card.single {
    padding-top: var(--vertical-block-spacing-lg);
    padding-bottom: var(--vertical-block-spacing-lg);
  }

  &.guide {
    padding-top: var(--vertical-block-spacing-xl);
    padding-bottom: var(--vertical-block-spacing-xl);
  }

  &.jumbotron {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: var(--vertical-block-spacing-xl);
    margin-bottom: var(--vertical-block-spacing-xl);

    @each $clr in $aTheBackgroundColors {
      &.bg-#{$clr} {
        padding-top: var(--vertical-block-spacing-xl);
        padding-bottom: var(--vertical-block-spacing-xl);
        margin-top: 0;
        margin-bottom: 0;

        & + .bg-#{$clr} {
          padding-top: 0;
        }
      }
    }
  }

  &.preamble {
    padding-top: var(--vertical-block-spacing-lg);
    padding-bottom: var(--vertical-block-spacing-lg);

    .component-inner {
      @include grid-layout();
      --row-gap: var(--vertical-block-spacing-md);

      .lead {
        @include breakpoint("sm") {
          grid-column: span 10;
        }

        @include breakpoint("xl") {
          grid-column: span 9;
        }

        @include breakpoint("2xl") {
          grid-column: span 7;
        }
      }

      .meta {
        @include breakpoint("sm") {
          grid-column: span var(--col-count);
        }
      }
    }
    // @apply py-20 2xl:py-28;
  }

  &.linkBlock {
    @apply py-20 2xl:py-28;

    h2 {
      @apply mb-0;
    }
  }

  &.accordion {

    > .component-inner {
      @include grid-layout();
      --row-gap: 5rem;
      --col-count: 12;

      .image-column {
        order: 2;
        grid-column: 2 / span 10;

        @include breakpoint("sm") {
          grid-column: 3 / span 8;
        }

        @include breakpoint("md") {
          order: 1;
          grid-column: span 5;
        }
      }

      .text-column {
        order: 1;
        grid-column: span var(--col-count);

        // @include breakpoint("sm") {
        //   grid-column: 2 / span 10;
        // }

        @include breakpoint("md") {
          order: 2;
          grid-column: 7 / span 6;
        }
      }
    }

    &.simple {
      header,
      dl {
        @apply flex-1 lg:w-1/2;
      }
    }

    &.large {
      .component-inner {
        @apply flex items-start justify-center gap-8;
      }
    }

    + .accordion {
      @apply pt-0;
    }
  }

  &.card {
    &.single {
      // @apply py-10 2xl:py-20;

      .component-inner {
        // @apply max-w-screen-xl;
        max-width: var(--content-max-width);

      }

      .card-item {
        @include grid-layout();


        .card-header {
          @include breakpoint("sm") {
            grid-column: span 8;
          }

          @include breakpoint("md") {
            grid-column: 2 / span 5;
          }
        }

        .illustration {
          order: -1;
          justify-self: start;

          @include breakpoint("sm") {
            grid-column: 11 / span 2;
            order: 2;
            justify-self: center;
            margin-top: auto;
          }

          @include breakpoint("md") {
            grid-column: 10 / span 2;

          }
        }
        // @apply flex-col lg:flex-row;
      }
    }

    &.multiple {
      @apply py-20 2xl:py-48;

      .component-inner {
        // @apply max-w-screen-xl;
        max-width: var(--content-max-width);
      }

      article {

        .wrapper {
          // @apply 2xl:pr-20 2xl:pl-0;
        }

        &:nth-child(even) {
          .wrapper {
            // @apply 2xl:pl-20 2xl:pr-0;
          }

          .illustration {
            // @apply lg:-order-1;
          }
        }

        &:not(:nth-last-child(1)) {
          @apply mb-24;
        }
      }
    }
  }

  &.categoryInclude {
    @apply py-20 2xl:py-28;
  }

  &.description {
    padding-top: var(--vertical-block-spacing-lg);
    padding-bottom: var(--vertical-block-spacing-lg);
    // @apply py-20 2xl:py-48;

    @include breakpoint-below("md") {
      padding-right: 0;
      padding-left: 0;
    }

    .items {
      --card-width: calc(((38rem - (2 * var(--component-outer-spacing)))) + var(--column-gap));

      @include breakpoint-below("md") {
        @include horizontal-overflow-container-style();
      }

      @include breakpoint("md") {
        // padding-left: var(--component-outer-spacing);
        // padding-right: var(--component-outer-spacing);
        display: flex;
        justify-content: center;
        column-gap: var(--column-gap);

        .article-item {
          flex: 1 1 25%;
        }
      }

      .article-item {
        @include breakpoint("sm") {
          max-width: 38rem;
        }

        @include breakpoint("xl") {
          max-width: 25%;
        }
      }

      // @include breakpoint("md") {
      //   @include grid-layout();
      // }
    }
  }

  &.enhanced {
    margin-top: var(--vertical-block-spacing-lg);
    margin-bottom: var(--vertical-block-spacing-lg);
    margin-left: var(--component-outer-spacing);
    margin-right: var(--component-outer-spacing);

    max-width: var(--content-max-width);

    @include and-bg-color-classes() {
      padding-top: var(--vertical-block-spacing-xl);
      padding-bottom: var(--vertical-block-spacing-xl);

      &:last-child {
        // margin-bottom: var(--vertical-block-spacing-xl);
        margin-bottom: var(--vertical-block-spacing-xl);
      }
    }



    &:not(:first-child) {
      //margin top?
      // but not if the previous block had background colour
    }

    &:not(:last-child) {
      //margin bottom?
    }

    @include breakpoint("2xl") {
      margin-left: auto;
      margin-right: auto;
      padding-left: 0;
      padding-right: 0;
    }

    > .component-inner {
      // @apply py-2 lg:p-8 2xl:px-32;
      // @apply py-2 lg:p-8 2xl:py-28;

      @include grid-layout();
      --row-gap: 3.5rem;

      @include breakpoint("sm") {
        --row-gap: 7rem;
      }

      @include breakpoint("2xl") {
        padding-left: 0;
        padding-right: 0;
      }

      .text-column {
        display: grid;
        row-gap: 3.5rem;

        @include breakpoint("sm") {
          grid-column: span 10;
        }

        @include breakpoint("lg") {
          grid-column: span 8;
        }

        @include breakpoint("xl") {
          grid-column: span 7;
        }

        @include breakpoint("2xl") {
          grid-column: 2 / span 6;
        }
      }

      .icon-column {
        @include breakpoint("sm") {
          grid-column: span 2;
        }

        @include breakpoint("lg") {
          grid-column: 10 / span 3;
          justify-content: center;
        }
      }

      .component-inner {
        // @apply mb-16;
      }

    }

    .content-inner {
      &.links {
        @apply text-lg xl:text-xl 2xl:text-2xl;


        li {
          @apply mb-4;
        }


        svg {
          display: none;
        }
      }
    }
  }

  &.feature {
    // @apply py-20 2xl:py-48;
    margin-top: var(--vertical-block-spacing-lg);
    margin-bottom: var(--vertical-block-spacing-lg);

    .feature-article {
      --row-gap: 4rem;
      @include grid-layout();

      @include breakpoint-below("sm") {
        .image-column {
          margin: 0 calc(50% - 50vw);
        }
      }

      @include breakpoint("sm") {
        .text-column {
          grid-column: span 5;
        }

        .image-column {
          grid-column: span 7;
        }
      }

      @include breakpoint("xl") {
        .text-column {
          grid-column: span 4;
        }

        .image-column {
          grid-column: 6 / span 7;
        }
      }
    }
  }

  &.guide {
    // @apply py-20 2xl:py-48;

    .block-title,
    .article-title {
      @apply font-SuisseIntlBold;
    }

    .block-title {
      font-size: 2.6rem;

      @include breakpoint("sm") {
        font-size: 3.2rem;
      }

      @include breakpoint("lg") {
        font-size: 4rem;
      }

      @include breakpoint("2xl") {
        font-size: 5rem;
      }
    }

    .article-title {
      font-size: inherit;
    }

    .component-inner {
      // @apply max-w-screen-xl;
      max-width: var(--content-max-width);
    }

    article {
      &:nth-child(even) {
        figure {
          @apply lg:-order-1;
        }

        header {
          div {
            @apply lg:ml-auto;
          }
        }
      }

      &:not(:nth-last-child(1)) {
        // @apply mb-24;
      }
    }
  }

  &.jumbotron {
    > .component-inner {
      max-width: var(--content-max-width);
      display: grid;
      --margin-top: 3rem;

      @include breakpoint("2xl") {
        --margin-top: 4rem;
      }

      @include breakpoint("4xl") {
        --margin-top: 5rem;
      }

      > .jumbotron-item {
        @include grid-layout();

        @include breakpoint("sm") {
          --col-count: 12;
        }

        > .component-header {
          @include breakpoint("sm") {
            grid-column: span 6;
          }

          @include breakpoint("md") {
            grid-column: span 5;
          }
        }

        > .text-column {
          @include breakpoint("sm") {
            // grid-column: span 6;
            grid-column: 7 / span 6;
          }

          @include breakpoint("md") {
            // grid-column: 6 / span 6;
          }
        }

        &.internalButton,
        &.externalButton {
          > .text-column {
            > .component-inner {
              display: flex;
            }

            .button {
              flex: 0 1 auto;
            }

            @include breakpoint("sm") {
              grid-column: 7 / span 6;
            }

            @include breakpoint("md") {
              // grid-column: 6 / span 6;
            }
          }
        }

        & + .jumbotron-item {
          margin-top: var(--margin-top);

          &:not(.internalButton),
          &:not(.externalButton) {
            --margin-top: 8rem;

            @include breakpoint("2xl") {
              --margin-top: 10rem;
            }

            @include breakpoint("4xl") {
              --margin-top: 15rem;
            }
          }
        }
      }
    }
  }

  &.quote {
    @apply py-16 2xl:py-20;
  }

  &.related {
    margin-top: var(--vertical-block-spacing-xl);
    margin-bottom: var(--vertical-block-spacing-xl);

    article {
      &.-card {
        @apply justify-items-stretch;

        a {
          @apply flex flex-col justify-self-stretch h-full;
        }
      }
    }
  }


  &.studies {
    // @apply py-20 2xl:py-28;
    padding: var(--vertical-block-spacing-lg) var(--component-outer-spacing);

    .component-inner {
      margin: 0 auto;
      max-width: var(--content-max-width);


      .items {
        @include grid-layout();
        --row-gap: var(--vertical-block-spacing-lg);

        .item {
          @include grid-layout();
          grid-column: span var(--col-count);

          .image-column,
          .text-column {
            @include breakpoint("sm") {
              grid-column: span var(--col-count);
            }
          }

          .image-column {
            @include breakpoint("lg") {
              grid-column: 1 / span 4;
            }

            @include breakpoint("2xl") {
              grid-column: 2 / span 4;
            }
          }

          .text-column {
            @include breakpoint("lg") {
              grid-column: 5 / span 8;
            }

            @include breakpoint("2xl") {
              grid-column: 6 / span 6;
            }
          }
        }


      }
    }
  }

  &.contact {
    > .component-inner {


      .contact-cards {
        --row-gap: var(--vertical-block-spacing-md);
        @include grid-layout();

        .card {
          grid-column: span var(--col-count);

          @include breakpoint("sm") {
            grid-column: span 6;
          }

          &:nth-child(odd) {
            @include breakpoint("2xl") {
              grid-column: 2 / span 5;
            }
          }

          &:nth-child(even) {
            @include breakpoint("2xl") {
              grid-column: span 5;
            }
          }
        }
      }
    }
  }

  &.team {
    padding: var(--vertical-block-spacing-lg) var(--component-outer-spacing) 0;

    .team-items {
      --row-gap: var(--vertical-block-spacing-lg);
      @include grid-layout();

      .team-item {
        @include breakpoint("sm") {
          grid-column: span 12;
        }

        // @include breakpoint("2xl") {
        //   grid-column: 2 / span 10;
        // }
      }
    }

    & + .component.contact {
      padding-top: 0;
      // padding-bottom: var(--vertical-block-spacing-xl);
      margin-top: var(--vertical-block-spacing-md);

      > .component-inner {
        border-top: .1rem solid var(--color-petrol_1);
        padding-top: var(--vertical-block-spacing-md);
      }
    }
  }

  &.anchor {
    .not-active {
      @apply hidden lg:flex;
    }

    // .active {
    //   @apply block;
    // }

  }

  &:last-child {
    margin-bottom: var(--vertical-block-spacing-xl);

    @include and-bg-color-classes() {
      margin-bottom: 0;
    }
  }
}



.video {
  &-container {
    overflow: hidden;
    position: relative;
    width:100%;

    &:after {
      padding-top: 56.25%;
      display: block;
      content: '';
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.section {
  // @apply  py-8;

  // &:nth-last-of-type(1) {
  //   @apply pb-0;
  // }
}

@mixin type-standard-content {
  @include breakpoint("sm") {
    grid-column: 2 / span 10;
  }

  @include breakpoint("md") {
    grid-column: 3 / span 8;
  }

  @include breakpoint("xl") {
    grid-column: 4 / span 6;
  }
}

.type {
  &.post,
  &.team {
    :where(.section.accordion, .section.paragraph, .section.media, .section.oembed, .section.quote, .section.related) {
      margin-top: var(--vertical-block-spacing-lg);
      margin-bottom: var(--vertical-block-spacing-lg);
    }

    :where(.section.accordion, .section.paragraph, .section.related, .section.oembed) {
      &:last-child {
        margin-bottom: var(--vertical-block-spacing-xl);
      }
    }

    .section {

      // margin-left: auto;
      // margin-right: auto;


      > .component-inner {
        max-width: var(--content-max-width);
        @include grid-layout();

        margin-left: auto;
        margin-right: auto;

        > * {
          @include type-standard-content();
        }
      }
    }

    .accordion {
      // @apply py-8;&.accordion {
      // padding-top: 0;
      // padding-bottom: 0;
    }

    .quote {
      @apply my-8;
    }

    .related {
      > .component-inner {
        > .block-header,
        > .posts {
          @include breakpoint("sm") {
            grid-column: span 12;
          }

          @include breakpoint("md") {
            grid-column: span 12;
          }

          @include breakpoint("xl") {
            grid-column: span 12;
          }
        }
      }
    }

    .oembed,
    .media {
      .component-inner {
        max-width: var(--content-max-width);

        > * {
          @include breakpoint("sm") {
            grid-column: span 12;
          }

          @include breakpoint("md") {
            grid-column: 2 / span 10;
          }

          @include breakpoint("xl") {
            grid-column: 3 / span 8;
          }
        }
      }
    }

    .quote {
      .component-inner {
        max-width: var(--content-max-width);
        --row-gap: 3rem;

        @include breakpoint("sm") {
          // @include grid-layout();
        }

        > .content {
          @include breakpoint("sm") {
            grid-column: 2 / span 10;
          }

          @include breakpoint("md") {
            grid-column: 2 / span 9;
          }

          @include breakpoint("xl") {
            grid-column: 3 / span 8;
          }

          // @include breakpoint("4xl") {
          //   grid-column: 3 / span 8;
          // }
        }

        > .icon-column {
          order: -1;

          @include breakpoint("md") {
            order: 1;
            grid-column: span 2;
          }

          @include breakpoint("md") {
            // grid-column: 11 / span 2;
          }

          @include breakpoint("lg") {
            grid-column: 11 / span 2;
          }

          @include breakpoint("4xl") {
            // grid-column: 12 / span 1;
          }
        }
      }
    }

    .paragraph {
      .paragraph-content {
        &.-highlighted {
          padding: 5rem 2.5rem;
          @include grid-layout();

          @include breakpoint("sm") {
            grid-column: span 12;
            padding: 5rem 0;
            // --col-count: 10;

            > * {
              grid-column: 2 / span calc(var(--col-count) - 2);
            }
          }

          @include breakpoint("md") {
            grid-column: 2 / span 10;
            --col-count: 10;
          }

          @include breakpoint("xl") {
            padding: 8rem 0;
            grid-column: 3 / span 8;
            --col-count: 8;
          }
        }
      }
    }

    .section.paragraph {

      h5 {
        @apply mb-4;
      }

      .highlighted {
        // @apply px-8 lg:px-0 py-20;

        h5 {
          @apply mb-6;
        }

        > * {
          // @apply lg:max-w-[804px] mx-auto;
        }
      }
    }

    .section.tips {
      padding-left: 0;
      padding-right: 0;

      .tips-inner {
        padding: var(--vertical-block-spacing-lg) var(--component-outer-spacing);
      }

      .component-inner {
        max-width: var(--content-max-width);
        margin-left: auto;
        margin-right: auto;
        @include grid-layout();
        --row-gap: var(--vertical-block-spacing-md);
      }

      .paragraph {
        @include type-standard-content();
      }

      .block-header {
        .title {
          margin-bottom: var(--vertical-block-spacing-sm);
        }

        @include breakpoint("sm") {
          grid-column: 1 / span 10;
        }

        @include breakpoint("xl") {
          grid-column: 1 / span 9;
        }
      }
    }

    .related {
      .component-inner {
        // @apply max-w-screen-2xl;
      }
    }
  }
}

.related {
  // @apply py-20 2xl:py-48;

  @include breakpoint-below("md") {
    padding-right: 0;
    padding-left: 0;

    .block-header {
      padding-left: var(--component-outer-spacing);
      padding-right: var(--component-outer-spacing);
    }
  }

  .posts {
    --card-width: calc(((72vw - (2 * var(--component-outer-spacing)))) + var(--column-gap));

    @include breakpoint("sm") {
      --card-width: calc(((52vw - (2 * var(--component-outer-spacing)))) + var(--column-gap));
    }

    @include breakpoint-below("md") {
      @include horizontal-overflow-container-style();
    }

    @include breakpoint("md") {
      @include grid-layout();
    }
  }

  &:not(.extended) {
    figure {
      @apply mb-6 lg:mb-10;
    }
  }

  .article-link {
    @include breakpoint("md") {
      grid-column: span 4;
    }

    &.-card {
      @apply justify-items-stretch;

      a {
        @apply flex flex-col justify-self-stretch h-full;
      }
    }
  }
}

.oembed,
.media {
  figure {
    @apply -mx-8 lg:mx-0;

    figcaption {
      @apply mx-8 lg:mx-0;
    }
  }
}




.section,
.component {
  // @apply break-words;
  // hyphens: auto;
  // -webkit-hyphens: auto;
  // overflow-wrap: anywhere;
  // -webkit-hyphenate-limit-before: 6;
  // -webkit-hyphenate-limit-after: 6;
  // hyphenate-limit-chars: 10 6 6;
//
//   .paragraph,
//   .accordion-item-details {
//     ul,
//     ol {
//       @apply list-outside pl-8;
//     }
//
//     ul {
//       @apply list-disc;
//     }
//
//     ol {
//       @apply list-decimal;
//     }
//   }
}

.icon {
  &-red {
    @apply text-red-dark;
  }

  &-yellow {
    @apply text-orange;
  }

  &-green {
    @apply text-green-dark;
  }

  &-blue {
    @apply text-brand-blue;
  }
}


.scrollTop {
  // button {
  //   @apply fixed bottom-8 xl:-mr-8 z-10;
  // }

  // &.isBottom {
  //   button {
  //     @apply relative bottom-0;
  //   }
  // }
}


// .internalButton {
//   @apply my-5;
// }

button,
a {
//   &.green,
//   &.green-light {
//     @apply p-4 text-xl lg:text-2xl flex-auto;
//   }
//
//   &.green {
//     @apply bg-brand-green text-green-light hover:bg-green-light hover:text-brand-green;
//   }
//
//   &.green-light {
//     @apply bg-green-light text-brand-green hover:bg-brand-green hover:text-green-light;
//   }

  &.text {
    @apply text-brand-green underline;
  }
}

// .accordion-item-details,
// .preamble,
// .paragraph {
//   a {
//     @apply underline hover:no-underline;
//   }
// }


.tooltip {
  @apply relative;

  &:hover,
  &:active,
  &:focus {
    &:after,
    &:before {
      min-width: 150px;
    }

    &:after {
      content: attr(data-tooltip);
      @apply bg-gray-dark p-2 rounded inline-block absolute -translate-x-2/4 -translate-y-full mx-auto -top-2 left-2/4 text-center text-sm text-black;
    }

    &:before {
      @apply bg-gray-dark -top-2 left-2/4 border-solid border-transparent h-0 w-0 absolute border-4 -ml-1 translate-x-0 translate-y-0;
    }
  }
}
